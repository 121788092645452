<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col v-if="buttonList.length > 0" :span="24">
        <el-form :inline="true" ref="selectForm" :model="selectForm" class="select_form">
          <el-form-item label="用户名:">
            <el-input v-model="selectForm.userName" placeholder="用户名"></el-input>
          </el-form-item>
          <el-form-item label="试用岗位:">
            <el-input v-model="selectForm.postName" placeholder="试用岗位"></el-input>
          </el-form-item>
          <toolbar :buttonList="buttonList" @callFunction="callFunction" :buttonListmsg="buttonListmsg"></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="calc-height">
      <el-table :data="users" highlight-current-row height="100%" @current-change="selectCurrentRow" @row-dblclick="handleShow" class="new_table">
        <el-table-column label="姓名" prop="UserName"></el-table-column>
        <el-table-column label="岗位名称" prop="PostName"> </el-table-column>
        <el-table-column label="开始时间" prop="StartDate" :formatter="formatStartTime"></el-table-column>
        <el-table-column label="试用期" prop="ProbationExplain"></el-table-column>
        <el-table-column label="转正时间" prop="EndDate"></el-table-column>
        <el-table-column label="转正结论" prop="Conclusion"></el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination align="center" @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next, jumper,total" :page-count="total" :total="totaldata"></el-pagination>
    
    <el-dialog  :title="operation3 ? '新增转正评价' : '修改转正评价'" :visible.sync="permissionVisible" v-model="permissionVisible" :close-on-click-modal="false">
      <el-form label-width="100px" :rules="postRules" ref="PermissionForm" :model="PermissionForm" class="demo-form-inline">
        <el-row>
          <el-col :span="12" style="display:none;">
            <el-form-item label="试用记录Id:" prop="probationId">
              <el-input type="text" v-model="PermissionForm.probationId" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display:none;">
            <el-form-item label="转正评价ID:" prop="id">
              <el-input type="text" v-model="PermissionForm.id" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="岗位名称:" prop="PostName">
              <el-input type="text" :disabled="true" v-model="PermissionForm.PostName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="姓名:" prop="UserName">
              <el-input type="text" :disabled="true" v-model="PermissionForm.UserName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display:none;">
            <el-form-item label="姓名ID:" prop="UserId">
              <el-input type="text" :disabled="true" v-model="PermissionForm.UserId"></el-input>
            </el-form-item>
          </el-col>
          
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="开始时间:" prop="StartDate">
              <el-input type="text" :disabled="true" v-model="PermissionForm.StartDate"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="试用期:" prop="ProbationExplain">
              <el-input type="text" :disabled="true" v-model="PermissionForm.ProbationExplain"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-table :data="PermissionForm.ItemArry" style="width: 100%" border>
            <el-table-column label="名称" prop="name" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.name }}</span>
                <!-- <el-input type="text" :placeholder="scope.row.name" v-model="scope.row.name"/> -->
              </template>
            </el-table-column>
            <el-table-column label="评分" prop="Score" align="center">
              <template slot-scope="scope">
                <span v-show="scope.row.isShow2">{{ scope.row.Score }}</span>
                <!-- <el-input-number :controls="false" size="small" type="text" :placeholder="scope.row.Score" v-model="scope.row.Score" v-show="!scope.row.isShow"/> -->
                <el-input type="text" :placeholder="scope.row.Score" v-model="scope.row.Score" v-show="scope.row.isShow1"/>
              </template>
            </el-table-column>
            <el-table-column label="评价内容" prop="Appraise" align="center">
              <template slot-scope="scope">
                <span v-show="scope.row.isShow2">{{ scope.row.Appraise }}</span>
                <el-input type="text" :placeholder="scope.row.Appraise" v-model="scope.row.Appraise" v-show="scope.row.isShow1"/>
              </template>
            </el-table-column>
            <el-table-column label="评价人" prop="AppraiseUser" align="center">
              <template slot-scope="scope">
                <span v-show="scope.row.isShow2">{{ scope.row.AppraiseUser }}</span>
                <el-input type="text" :placeholder="scope.row.AppraiseUser" v-model="scope.row.AppraiseUser" v-show="scope.row.isShow1"/>
              </template>
            </el-table-column>
            <el-table-column align="center">
              <template slot-scope="scope">
                <el-button size="mini" @click="handleEdit1(scope.$index, scope.row)" v-show="scope.row.isShow">编辑</el-button>
                <el-button  @click="hold(scope.$index, scope.row)" size="mini" v-show="scope.row.isShow1">保存</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row style="margin-top:10px;">
           <el-col :span="12">
            <el-form-item label="是否通过试用:" prop="state" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-select v-model="PermissionForm.state" style="width: 100%">
                <el-option label="是" value="true"></el-option>
                <el-option label="否" value="false"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="text-align: center">
              <el-button type="primary" v-on:click="onPermissionSubmit('PermissionForm')">提交</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog title="转正" :visible.sync="permissionVisible1" v-model="permissionVisible1" :close-on-click-modal="false">
      <el-form label-width="100px" :rules="postRules" ref="PermissionForm1" :model="PermissionForm1" class="demo-form-inline">
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名:" prop="UserName">
              <el-input type="text" :disabled="true" v-model="PermissionForm1.UserName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display:none;">
            <el-form-item label="姓名ID:" prop="userId">
              <el-input type="text" :disabled="true" v-model="PermissionForm1.userId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="原岗位:" prop="OldPost">
              <el-input type="text" :disabled="true" v-model="PermissionForm1.OldPost">
                
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="原部门:" prop="OldDpt">
              <el-input type="text" v-model="PermissionForm1.OldDpt" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display:none;">
            <el-form-item label="试用记录Id:" prop="probationId">
              <el-input type="text" v-model="PermissionForm1.probationId" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="转正时公司:" prop="Factory">
              <el-input type="text" v-model="PermissionForm1.Factory" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="转正时部门:" prop="Dpt">
              <el-input type="text" v-model="PermissionForm1.Dpt" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="转正时岗位:" prop="Post" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="PermissionForm1.Post" :disabled="true">
                <el-button slot="append" icon="el-icon-search" @click="chooseUser"></el-button>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="display:none;">
          <el-col :span="12">
            <el-form-item label="部门ID:" prop="DptId">
              <el-input type="text" v-model="PermissionForm1.DptId" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="岗位ID:" prop="PostId">
              <el-input type="text" v-model="PermissionForm1.PostId" :disabled="true"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="工作性质Id:" prop="JobType">
              <el-input type="text" :disabled="true" v-model="PermissionForm1.JobType"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="StartDate" label="转正日期:">
              <el-date-picker type="date" v-model="PermissionForm1.StartDate" value-format="yyyy-MM-dd" placeholder="选择转正日期" style="“width: 100%”"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
          <el-form-item label="转正工资执行日期:" prop="salaryDate">
              <!-- <el-date-picker type="date" v-model="PermissionForm1.salaryDate" value-format="yyyy-MM" placeholder="选择转正工资执行日期" style="“width: 100%”"></el-date-picker> -->
              <el-date-picker v-model="PermissionForm1.salaryDate" type="month" format="yyyy-MM" value-format="yyyy-MM"  placeholder="选择转正工资执行日期" style="“width: 100%”"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="是否提前转正:" prop="EarlyWork" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-radio-group v-model="PermissionForm1.EarlyWork" class="ml-4">
                <el-radio label="是" size="small">是</el-radio>
                <el-radio label="否" size="small">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
          <el-form-item label="说明:" prop="Remark">
              <el-input type="text" v-model="PermissionForm1.Remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="display:none;">
          <el-col :span="12">
            <el-form-item label="审批状态:" prop="ApprovalState">
              <el-input type="text" :disabled="true" v-model="PermissionForm1.ApprovalState"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="银行名称:" prop="BankCardName">
              <el-input type="text" v-model="PermissionForm1.BankCardName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="银行卡号:" prop="BankCardCD">
              <el-input type="text" v-model="PermissionForm1.BankCardCD"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="是否年功:" prop="IsYear" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-radio-group v-model="PermissionForm1.IsYear" class="ml-4">
                <el-radio label="是" size="small">是</el-radio>
                <el-radio label="否" size="small">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="超龄年功工资:" prop="OverAgeUserAvailable">
              <el-input type="text" v-model="PermissionForm1.OverAgeUserAvailable" @blur="inputMoney('OverAgeUserAvailable')">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="岗位工资:" prop="WorkSalary">
              <el-input type="text" v-model="PermissionForm1.WorkSalary" @blur="inputMoney('WorkSalary')">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="职务工资:" prop="PostSalaryName">
                <el-select v-model="PermissionForm1.PostSalaryName" placeholder="职务工资" style="width: 100%" @change="selectTreatment()">
                <el-option label="无" value="无"></el-option>
                <el-option label="300" value="300"></el-option>
                <el-option label="400" value="400"></el-option>
                <el-option label="500" value="500"></el-option>
                <el-option label="600" value="600"></el-option>
                <el-option label="800" value="800"></el-option>
              </el-select>
              <!-- </el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display:none;">
            <el-form-item label="职务工资Id:" prop="PostSalaryId">
              <el-input type="text" v-model="PermissionForm1.PostSalaryId" :disabled="true">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <!-- <el-col :span="12">
            <el-form-item label="职务工资名称:" prop="PostSalaryName">
              <el-input type="text" v-model="PermissionForm1.PostSalaryName">
              </el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="工资类型:" prop="SalaryType" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-select v-model="PermissionForm1.SalaryType" placeholder="薪资类型" style="width: 100%" @change="selectSalaryType()">
                <el-option label="基本工资" value="基本工资"></el-option>
                <el-option label="主机公司" value="主机公司"></el-option>
                <el-option label="计件工资" value="计件工资"></el-option>
                <el-option label="不计入工资" value="不计入工资"></el-option>
                <el-option label="后勤新入职" value="后勤新入职"></el-option>
                <el-option label="离职" value="离职"></el-option>
                <el-option label="驻外人员" value="驻外人员"></el-option>
                <el-option label="产检/长假" value="产检/长假"></el-option>
                <el-option label="日工资" value="日工资"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display:none;">
            <el-form-item label="工资类型ID:" prop="SalaryTypeId">
              <el-input type="text" v-model="PermissionForm1.SalaryTypeId" :disabled="true">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="社保:" prop="FlowInsuranceState" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-select v-model="PermissionForm1.FlowInsuranceState" placeholder="社保" style="width: 100%">
                <el-option label="不交保险" value="不交保险"></el-option>
                <el-option label="交保险入职已签批" value="交保险入职已签批"></el-option>
                <el-option label="交保险入职未签批" value="交保险入职未签批"></el-option>
                <el-option label="已交保险" value="已交保险"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="日保健费:" prop="CareDaySalary">
              <el-input type="text" v-model="PermissionForm1.CareDaySalary" @blur="inputMoney('CareDaySalary')">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日工资单价:" prop="DaySalary">
              <el-input type="text" v-model="PermissionForm1.DaySalary" @blur="inputMoney('DaySalary')">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="特岗补贴:" prop="SpecialSalary">
              <el-input type="text" v-model="PermissionForm1.SpecialSalary" @blur="inputMoney('SpecialSalary')">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="伙食单价:" prop="EateDaySalary">
              <el-input type="text" v-model="PermissionForm1.EateDaySalary" @blur="inputMoney('EateDaySalary')">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="驻外补贴:" prop="OutsideSalary">
              <el-input type="text" v-model="PermissionForm1.OutsideSalary" @blur="inputMoney('OutsideSalary')">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车辆补贴:" prop="BusMoney">
              <el-input type="text" v-model="PermissionForm1.BusMoney" @blur="inputMoney('BusMoney')">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="值班日保健费:" prop="OndutyCareSalary">
              <el-input type="text" v-model="PermissionForm1.OndutyCareSalary" @blur="inputMoney('OndutyCareSalary')">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="爱心基金:" prop="LoveMoney">
              <el-input type="text" v-model="PermissionForm1.LoveMoney" @blur="inputMoney('LoveMoney')">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="工人系数:" prop="WorkerMod">
              <el-input type="text" v-model="PermissionForm1.WorkerMod" @blur="inputMoney('WorkerMod')">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="租房补贴:" prop="HouseSalary">
              <el-input type="text" v-model="PermissionForm1.HouseSalary" @blur="inputMoney('HouseSalary')">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="绩效工资:" prop="PerfSalary">
              <el-input type="text" v-model="PermissionForm1.PerfSalary" @blur="inputMoney('PerfSalary')">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考勤组名称" prop="AttendGroupName" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
            <el-input type="text" :disabled="true" v-model="PermissionForm1.AttendGroupName">
                <el-button slot="append" icon="el-icon-search" @click="selectAddress"></el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display:none;">
            <el-form-item label="考勤组Id:" prop="AttendGroupId">
              <el-input type="text" :disabled="true" v-model="PermissionForm1.AttendGroupId"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <upload-files :files="PermissionForm1.FileArry" :key="key" action="/cyl/ftp/ftp/upload" :limit="20" @fun="dealFiles" :IsDisabled="operation1" :IsDel="operation1"></upload-files>
        <el-row>
          <el-col :span="24" style="text-align: center">
              <el-button type="primary" v-on:click="onPermissionSubmit1('PermissionForm1')">提交</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="addUserVisible" v-model="addUserVisible" width="75%" center append-to-body>
      <UserChoosejob :data="choosedusers" :all="true" :single="false" @callback="usercallFunction"></UserChoosejob>
    </el-dialog>
    <el-dialog :title="operation ? '查看' : '编辑'"  :visible.sync="permissionShowVisible" v-model="permissionShowVisible" :close-on-click-modal="false">
      <el-form label-width="100px" ref="PermissionShowForm"
        :model="PermissionShowForm"
        class="demo-form-inline">
        <el-row>
          <el-col :span="12">
            <el-form-item label="岗位:" prop="Post">
              <el-input type="text" :disabled="true" v-model="PermissionShowForm.Post">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="姓名:" prop="UserName">
              <el-input type="text" :disabled="true" v-model="PermissionShowForm.UserName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display:none;">
            <el-form-item label="姓名ID:" prop="UserId">
              <el-input type="text" :disabled="true" v-model="PermissionShowForm.UserId"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="开始时间:" prop="StartDate">
              <el-input type="text" :disabled="true" v-model="PermissionShowForm.StartDate"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="试用期:" prop="ProbationExplain">
              <el-input type="text" :disabled="true" v-model="PermissionShowForm.ProbationExplain"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
      <el-dialog :visible.sync="addressVisible" v-model="addressVisible" width="75%" title="考勤地点" center append-to-body>
      <el-table ref="multipleTable1" :data="AreaData" highlight-current-row max-height="300" style="width: 100%" @current-change="handleSelectionChange1">
        <el-table-column prop="Name" label="考勤组名称"> </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-button v-on:click="areaSelection">确认选择</el-button>
      </div>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import {
  getProbationPageList,
  getProbationInfoList,
  getInfoList,
  GetUserDis,
  AddPositive,
  GetManagePost,
  ProbationEvaluate,
  AddProbationEvaluate,
  GetInfoBypPobationId,
  GetDepartmentOrg,
  GetSubordinateInfoPageList,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
import UserChoosejob from "../../components/UserChoosejob";
    import UploadFiles from "../../components/UploadFiles";
    import DptTool from "../../components/Org/DptTool"; // 选取部门控件
export default {
        components: { Toolbar, UserChoosejob, UploadFiles, DptTool},
  data() {
    return {
      key: 0,
      loading: true,
      submitPlay: true,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      searchVal: "",
      filters: {
        name: "",
      },
      operation3:true,
      operation1: true,
      multipleSelection1:[],
      AreaData:[],
      addressVisible: false,
      selectForm: {
        postName: "",
        startDate: "",
        endDate: "",
        proExplain: "",
        salaryType: "",
        positionSal: "",
        userName: "",
      },
      selectForm1: {
        postName: "",
        startDate: "",
        endDate: "",
        proExplain: "",
        salaryType: "",
        positionSal: "",
        userName: "",
      },
      operation: true,// true:新增, false:编辑
      PermissionShowForm: [],
      addUserVisible: false,
      PositionLists: [],
      SalaryTypeLists: [],
      choosedusers: [],
      buttonListmsg: "",
      interviewVisible: false,
      userDetailVisible: false,
      users: [],
      buttonList: [],
      permissionVisible: false,
      permissionVisible1: false,
      permissionShowVisible: false,
      PermissionForm: {
        userId: "",
        Post: "",
        PostId: "",
        StartDate: "",
        SpecialSalary: "0",
        EateDaySalary: "",
        BusMoney:"0",
        CareDaySalary: "0",
        EateDaySalary:"0",
        PerfSalary:"0",
        HouseSalary:"0",
        WorkerMod:"0",
        LoveMoney:"0",
        OndutyCareSalary:"0",
        DaySalary:"0",
        OutsideSalary: "0",
        ProbationExplain: "",
        // PositiveExplain: "",
        BusMoney: "",
        WorkSalary: "",
        // FundsDeduct: "",
        DaySalary: "",
        BankCardName: "",
        // BankAddress: "",
        BankCardCD: "",
        // PositionId: "",
        PostSalaryName: "",
        SalaryType: "",
        SalaryTypeId: "",
        salaryDate: "",
        probationId: "",
        FileArry: [],
         AttendGroupName:"",
         ItemArry: [
          {
          name: "通用基础类",
          Appraise: "",
          Score: "0",
          AppraiseUser:"",
          isShow: true,
          isShow2: true,
          isShow1: false,
        },
        {
          name: "岗位专业类",
          Appraise: "",
          Score: "0",
          AppraiseUser:"",
          isShow: true,
          isShow2: true,
          isShow1: false,
        },
        {
          name: "工作表现",
          Appraise: "",
          Score: "0",
          AppraiseUser:"",
          isShow: true,
          isShow2: true,
          isShow1: false,
        }
        ],
      },
      PermissionForm1: {
        OldDpt:"",
        userId: "",
        Post: "",
        PostId: "",
        StartDate: "",
        SpecialSalary: "0",
        CareDaySalary: "0",
        BusMoney:"0",
        EateDaySalary:"0",
        PerfSalary:"0",
        HouseSalary:"0",
        WorkerMod:"0",
        LoveMoney:"0",
        OndutyCareSalary:"0",
        DaySalary:"0",
        OutsideSalary: "0",
        ProbationExplain: "",
        // PositiveExplain: "",
        WorkSalary: "",
        // FundsDeduct: "",
        BankCardName: "",
        // BankAddress: "",
        BankCardCD: "",
        // PositionId: "",
        PostSalaryName: "",
        SalaryType: "",
        SalaryTypeId: "",
        salaryDate: "",
        probationId: "",
        Factory:"",
        EarlyWork:"",
        FlowInsuranceState:"",
        Dpt:"",
      },
      EateSalaryLists: [
        {
          value: "0",
          label: "0",
        },
        {
          value: "5",
          label: "5",
        },
        {
          value: "10",
          label: "10",
        },
        {
          value: "25",
          label: "25",
        },
        {
          value: "30",
          label: "30",
          },
          {
              value: "45",
              label: "45",
          },
          {
              value: "60",
              label: "60",
          },
      ],
      CareDaySalaryLists: [
        {
          value: "5",
          label: "5",
        },
        {
          value: "10",
          label: "10",
        },
      ],
      OutsideSalaryLists: [
        {
          value: "300",
          label: "300",
        },
        {
          value: "500",
          label: "500",
        },
      ],
      ProbationExplainLists: [
        {
          value: "0",
          label: "无",
        },
        {
          value: "7",
          label: "7天",
        },
        {
          value: "1",
          label: "1个月",
        },
        {
          value: "2",
          label: "2个月",
        },
        {
          value: "3",
          label: "3个月",
        },
        {
          value: "5",
          label: "5个月",
        },
      ],
      postRules: {
        // Post: [{ required: true, message: "请选择岗位", trigger: "blur" }],
        // EateDaySalary: [
        //   { required: true, message: "请填写伙食日单价", trigger: "blur" },
        // ],
        // WorkSalary: [
        //   { required: true, message: "请填写岗位工资", trigger: "blur" },
        // ],
        // StartDate: [
        //   { required: true, message: "请选择开始时间", trigger: "blur" },
        // ],
        // PostSalaryName: [
        //   { required: true, message: "请选择职务待遇", trigger: "blur" },
        // ],
        // SalaryType: [
        //   { required: true, message: "请选择工资类型", trigger: "blur" },
        // ],
        // salaryDate: [
        //   {
        //     required: true,
        //     message: "请选择转正工资执行日期",
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  methods: {
    selectSalaryType(){//薪资类型
      if(this.PermissionForm1.SalaryType=="基本工资"){
        this.PermissionForm1.SalaryTypeId='1';
      }else if(this.PermissionForm1.SalaryType=="主机公司"){
        this.PermissionForm1.SalaryTypeId='2';
      }else if(this.PermissionForm1.SalaryType=="计件工资"){
        this.PermissionForm1.SalaryTypeId='3';
      }else if(this.PermissionForm1.SalaryType=="不计入工资"){
        this.PermissionForm1.SalaryTypeId='4';
      }else if(this.PermissionForm1.SalaryType=="后勤新入职"){
        this.PermissionForm1.SalaryTypeId='5';
      }else if(this.PermissionForm1.SalaryType=="离职"){
        this.PermissionForm1.SalaryTypeId='6';
      }else if(this.PermissionForm1.SalaryType=="驻外人员"){
        this.PermissionForm1.SalaryTypeId='7';
      }else if(this.PermissionForm1.SalaryType=="产检/长假"){
        this.PermissionForm1.SalaryTypeId='8';
      }else if(this.PermissionForm1.SalaryType=="日工资"){
        this.PermissionForm1.SalaryTypeId='9';
      }
    },
    selectTreatment() {//选择职务工资
      if(this.PermissionForm1.PostSalaryName=="无"){
        this.PermissionForm1.PostSalaryId='0';
      }else if(this.PermissionForm1.PostSalaryName=="300"){
        this.PermissionForm1.PostSalaryId='39f722c9-7a8b-5d57-c217-b46c87167db5';
      }else if(this.PermissionForm1.PostSalaryName=="400"){
        this.PermissionForm1.PostSalaryId='39f722c9-7a8b-2ea7-1a61-5d38c36b32a8';
      }else if(this.PermissionForm1.PostSalaryName=="500"){
        this.PermissionForm1.PostSalaryId='39f722c9-7a8b-4763-1305-8b2dafff36be';
      }else if(this.PermissionForm1.PostSalaryName=="600"){
        this.PermissionForm1.PostSalaryId='39f722c9-7a8b-edec-7a2a-df6a0fc21f95';
      }else if(this.PermissionForm1.PostSalaryName=="800"){
        this.PermissionForm1.PostSalaryId='39f722c9-7a8b-aa0b-336f-2579602822cc';
      }
     
    },
          handleSelectionChange1(val) {
          console.log(val)
      this.multipleSelection1 = val;
    },
    /* 编辑 */
    handleEdit1(index, row) {
      row.isShow = false;
      row.isShow2 = false;
      row.isShow1 = true;
    },
    /* 保存 */
    hold(index, row) {
      row.isShow = true;
      row.isShow2 = true;
      row.isShow1 = false;
    },
      areaSelection() {
        console.log( this.multipleSelection1)
        this.PermissionForm1.AttendGroupName = this.multipleSelection1.Name
        this.PermissionForm1.AttendGroupId = this.multipleSelection1.Id
      this.addressVisible = false;
     
    },
      selectAddress() {
      let that = this;
      this.$api.GetAllAttendGroup().then((res) => {
        this.AreaData = res.data.response;
        this.addressVisible = true;
        
      });
 
        
    
      
    },
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    //获取用户列表
    getProbation(isCurrent = true) {//查询
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        postName: this.selectForm.postName,
        startDate: this.selectForm.startDate,
        endDate: this.selectForm.endDate,
        proExplain: this.selectForm.proExplain,
        salaryType: this.selectForm.salaryType,
        positionSal: this.selectForm.positionSal,
        userName: this.selectForm.userName,
      };
      console.log(para);
      GetSubordinateInfoPageList(para).then((res) => {
        console.log(res);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    inputMoney(name) {
      if (!validate.validateIsMoney(this.PermissionForm1[name])) {
        this.$message({
          message: "请输入数字类型",
          type: "error",
          duration: 5000,
        });
        this.PermissionForm1[name] = null;
        return;
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getProbation(false);
    },
    usercallFunction(newdata) {
      console.log(newdata)
      this.PermissionForm1.Post = newdata.Name;
      this.PermissionForm1.JobType=newdata.WorkTypeId;
      this.PermissionForm1.PostId=newdata.Id;
      this.PermissionForm1.DptId=newdata.DepartmentId;
        GetDepartmentOrg({ deptId: newdata.DepartmentId }).then((res) => {
        if (res.data.code == 200) {
          console.log(res.data.data.queryDeptName)
          this.PermissionForm1.Dpt = res.data.data.queryDeptName;
        } else {
          this.$message({
            message: "获取部门信息出错",
            type: "error",
            duration: 5000,
          });
        }
      });
      this.addUserVisible = false;
    },

    chooseUser() {
      this.addUserVisible = true;
    },
    //添加转正评价
    addPositive() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      console.log(row);
      GetInfoBypPobationId({ probationId: row.Id }).then((res) => {
        console.log(res.data.response);
        if (res.data.response==null){
          this.PermissionForm= {
            probationId : row.Id,
            PostName:row.PostName,
            UserName:row.UserName,
            UserId:row.UserId,
            StartDate:row.StartDate,
            ProbationExplain:row.ProbationExplain,
            id:"",
            state:"",
            
            ItemArry:[
              {
                name: "通用基础类",
                Appraise: "",
                Score: "",
                AppraiseUser:"",
                isShow: true,
                isShow2: true,
                isShow1: false,
              },
              {
                name: "岗位专业类",
                Appraise: "",
                Score: "",
                AppraiseUser:"",
                isShow: true,
                isShow2: true,
                isShow1: false,
              },
              {
                name: "工作表现",
                Appraise: "",
                Score: "",
                AppraiseUser:"",
                isShow: true,
                isShow2: true,
                isShow1: false,
              }
            ]
          };
          this.operation3=true;
        }else{
          this.PermissionForm= {
            probationId : row.Id,
            PostName:row.PostName,
            UserName:row.UserName,
            UserId:row.UserId,
            StartDate:row.StartDate,
            ProbationExplain:row.ProbationExplain,
            id:res.data.response.id,
            state:res.data.response.state,
            
            ItemArry:[
              {
                name:  res.data.response.ItemArry[0].name,
                Appraise: res.data.response.ItemArry[0].Appraise,
                Score: res.data.response.ItemArry[0].Score,
                AppraiseUser:res.data.response.ItemArry[0].AppraiseUser,
                isShow: true,
                isShow2: true,
                isShow1: false,
              },
              {
                name:  res.data.response.ItemArry[1].name,
                Appraise:res.data.response.ItemArry[1].Appraise,
                Score: res.data.response.ItemArry[1].Score,
                AppraiseUser:res.data.response.ItemArry[1].AppraiseUser,
                isShow: true,
                isShow2: true,
                isShow1: false,
              },
              {
                name:  res.data.response.ItemArry[2].name,
                Appraise: res.data.response.ItemArry[2].Appraise,
                Score: res.data.response.ItemArry[2].Score,
                AppraiseUser:res.data.response.ItemArry[2].AppraiseUser,
                isShow: true,
                isShow2: true,
                isShow1: false,
              }
            ]
          };
          this.operation3=false;
        }
      });
      this.permissionVisible = true;
    },
    //添加转正
    Positive() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      console.log(row);

      GetInfoBypPobationId({ probationId: row.Id }).then((res) => {
        console.log(res.data.response);
        if(res.data.response==null){
          this.$message({
            message: "未填写转正评价，不可发起转正流程！",
            type: "error",
          });
          return;
        }else{
          if(res.data.response.state=="true"){
            this.PermissionForm1.OldPost=row.PostName;
            // this.PermissionForm1.PostId=row.PostId;
            this.PermissionForm1.UserName=row.UserName;
            this.PermissionForm1.userId=row.UserId;
            // this.PermissionForm1.DptId=row.DptId;
            this.PermissionForm1.OldDpt=row.DptName;
            this.PermissionForm1.probationId=row.Id;
            // this.PermissionForm1.StartDate=row.StartDate;
            this.PermissionForm1.ProbationExplain=row.ProbationExplain;
            GetDepartmentOrg({ deptId: row.DptId }).then((res) => {
              if (res.data.code == 200) {
                console.log(res.data.data.deptName)
                this.PermissionForm1.Factory = res.data.data.deptName;
              } else {
                this.$message({
                  message: "获取部门信息出错",
                  type: "error",
                  duration: 5000,
                });
              }
            });
            this.permissionVisible1 = true;
          }else{
            this.$message({
              message: "未通过试用，不可发起转正流程！",
              type: "error",
            });
            return;
          }
          
        }
      });

      
    },
    handleShow() {//查看
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      console.log(row);
      this.PermissionShowForm.Post=row.PostName;
      this.PermissionShowForm.UserName=row.UserName;
      this.PermissionShowForm.UserId=row.UserId;
      this.PermissionShowForm.StartDate=row.StartDate;
      this.PermissionShowForm.ProbationExplain=row.ProbationExplain;

      this.permissionShowVisible = true;
      this.operation=true;

    },

    // 转正评价
    onPermissionSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let para = Object.assign({}, this.PermissionForm);
            console.log(para)
            if (this.operation3) {
              //新增
              this.$api.AddProbationEvaluate(para).then((res) => {
                console.log(res);
                if (res.data.success) {
                  this.$message({
                    message: "添加成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.permissionVisible = false;
                  this.getData();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                    duration: 5000,
                  });
                }
              });
            } else {
              //修改
              this.$api.UpdateProbationEvaluate(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: "修改成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.permissionVisible = false;
                  this.getData();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                    duration: 5000,
                  });
                }
              });
            }
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
            return false;
          }
        });
      });
    },


    onPermissionSubmit1(formName) {//触发转正流程
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {

            let para = Object.assign({}, this.PermissionForm1);
              ProbationEvaluate(para).then((res) => {
                // console.log(res.data.success)
                if (res.data.success) {
                  this.$message({
                    message: "成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.permissionVisible1 = false;
                  // this.getData();
                } else {
                  this.$message({
                    message: "失败",
                    type: "error",
                    duration: 5000,
                  });
                }
              });
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
            return false;
          }
        });
      });
    },


    PositionModel(id) {
      var obj = {};
      obj = this.PositionLists.find((item) => {
        //model就是上面的数据源
        console.log(item);
        return item.LeaveName === id; //筛选出匹配数据
      });
      this.PermissionForm.PostSalaryId = obj.Id;
    },
    SalaryTypeModel(id) {
      var obj = {};
      obj = this.SalaryTypeLists.find((item) => {
        //model就是上面的数据源
        return item.Type === id; //筛选出匹配数据
      });

      this.PermissionForm.SalaryTypeId = obj.Id;
    },
    dealFiles(data) {
      console.log(data.backData);
      this.PermissionForm.FileArry = data.backData;
      // this.fileList = data.backData;
      // console.log('dealFiles-list:' + this.fileList);
    },
  },
  mounted() {
    this.getProbation();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>

